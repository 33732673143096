define("discourse/plugins/automation/admin/routes/admin-plugins-discourse-automation-new", ["exports", "rsvp", "discourse/routes/discourse"], function (_exports, _rsvp, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AutomationNew extends _discourse.default {
    controllerName = "admin-plugins-discourse-automation-new";
    model() {
      return (0, _rsvp.hash)({
        scriptables: this.store.findAll("discourse-automation-scriptable"),
        automation: this.store.createRecord("discourse-automation-automation")
      });
    }
  }
  _exports.default = AutomationNew;
});