define("discourse/plugins/automation/admin/templates/components/form-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @error}}
    <div class="alert alert-error form-errors">
      {{html-safe @error}}
    </div>
  {{/if}}
  */
  {
    "id": "W+lLGCr3",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"alert alert-error form-errors\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@error\"],false,[\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/automation/admin/templates/components/form-error.hbs",
    "isStrictMode": false
  });
});