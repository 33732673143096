define("discourse/plugins/automation/admin/templates/components/topic-trigger", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">
      {{i18n "discourse_automation.triggerables.topic.topic_id.label"}}
    </label>
  
    <div class="controls">
      <Input
        @value={{metadata.topic_id}}
        {{on "input" (action (mut metadata.topic_id) value="target.value")}}
      />
    </div>
  </div>
  */
  {
    "id": "aLeOW9Bg",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"discourse_automation.triggerables.topic.topic_id.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,1],[[4,[38,2],[\"input\",[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"metadata\",\"topic_id\"]]],null]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@value\"],[[30,0,[\"metadata\",\"topic_id\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `metadata` property path was used in the `discourse/plugins/automation/admin/templates/components/topic-trigger.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.metadata}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `metadata` property path was used in the `discourse/plugins/automation/admin/templates/components/topic-trigger.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.metadata}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"input\",\"on\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/automation/admin/templates/components/topic-trigger.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});