define("discourse/plugins/automation/admin/controllers/admin-plugins-discourse-automation-new", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _service, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AutomationNew extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    form = null;
    error = null;
    init() {
      super.init(...arguments);
      this._resetForm();
    }
    saveAutomation() {
      this.set("error", null);
      this.model.automation.save(this.form.getProperties("name", "script")).then(() => {
        this._resetForm();
        this.router.transitionTo("adminPlugins.discourse-automation.edit", this.model.automation.id);
      }).catch(e => {
        this.set("error", (0, _ajaxError.extractError)(e));
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "saveAutomation", [_object.action]))();
    _resetForm() {
      this.set("form", _object.default.create({
        name: null,
        script: null
      }));
    }
  }
  _exports.default = AutomationNew;
});