define("discourse/plugins/automation/admin/components/fields/da-key-value-field", ["exports", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/components/d-button", "discourse/components/modal/json-schema-editor", "discourse-i18n", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _object, _dButton, _jsonSchemaEditor, _discourseI18n, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class KeyValueField extends _daBaseField.default {
    static #_ = (() => dt7948.g(this.prototype, "showJsonEditorModal", [_tracking.tracked], function () {
      return false;
    }))();
    #showJsonEditorModal = (() => (dt7948.i(this, "showJsonEditorModal"), void 0))();
    jsonSchema = {
      type: "array",
      uniqueItems: true,
      items: {
        type: "object",
        title: "group",
        properties: {
          key: {
            type: "string"
          },
          value: {
            type: "string",
            format: "textarea"
          }
        }
      }
    };
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field key-value-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <DButton class="configure-btn" @action={{this.openModal}}>
                {{this.showJsonModalLabel}}
              </DButton>
    
              {{#if this.showJsonEditorModal}}
                <ModalJsonSchemaEditor
                  @model={{hash
                    value=this.value
                    updateValue=this.handleValueChange
                    settingName=@label
                    jsonSchema=this.jsonSchema
                  }}
                  @closeModal={{this.closeModal}}
                />
              {{/if}}
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "WU6hREdJ",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field key-value-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],[[24,0,\"configure-btn\"]],[[\"@action\"],[[30,0,[\"openModal\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"showJsonModalLabel\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showJsonEditorModal\"]],[[[1,\"            \"],[8,[32,2],null,[[\"@model\",\"@closeModal\"],[[28,[32,3],null,[[\"value\",\"updateValue\",\"settingName\",\"jsonSchema\"],[[30,0,[\"value\"]],[30,0,[\"handleValueChange\"]],[30,1],[30,0,[\"jsonSchema\"]]]]],[30,0,[\"closeModal\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-key-value-field.js",
      "scope": () => [_daFieldLabel.default, _dButton.default, _jsonSchemaEditor.default, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
    get value() {
      return this.args.field.metadata.value || '[{"key":"example","value":"You posted {{key}}"}]';
    }
    get keyCount() {
      if (this.args.field.metadata.value) {
        return JSON.parse(this.value).length;
      }
      return 0;
    }
    get showJsonModalLabel() {
      if (this.keyCount === 0) {
        return _discourseI18n.default.t("discourse_automation.fields.key_value.label_without_count");
      } else {
        return _discourseI18n.default.t("discourse_automation.fields.key_value.label_with_count", {
          count: this.keyCount
        });
      }
    }
    handleValueChange(value1) {
      if (value1 !== this.args.field.metadata.value) {
        this.mutValue(value1);
        this.args.saveAutomation();
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleValueChange", [_object.action]))();
    openModal() {
      this.showJsonEditorModal = true;
    }
    static #_4 = (() => dt7948.n(this.prototype, "openModal", [_object.action]))();
    closeModal() {
      this.showJsonEditorModal = false;
    }
    static #_5 = (() => dt7948.n(this.prototype, "closeModal", [_object.action]))();
  }
  _exports.default = KeyValueField;
});