define("discourse/plugins/automation/admin/components/fields/da-date-time-field", ["exports", "@ember/component", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _dButton, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DateTimeField extends _daBaseField.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field date-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <div class="controls-row">
                <Input
                  @type="datetime-local"
                  @value={{readonly this.localTime}}
                  disabled={{@field.isDisabled}}
                  {{on "input" this.convertToUniversalTime}}
                />
    
                {{#if @field.metadata.value}}
                  <DButton
                    @icon="trash-can"
                    @action={{this.reset}}
                    @disabled={{@field.isDisabled}}
                  />
                {{/if}}
              </div>
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "RQD6Q6VM",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field date-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"controls-row\"],[12],[1,\"\\n            \"],[8,[32,1],[[16,\"disabled\",[30,2,[\"isDisabled\"]]],[4,[32,2],[\"input\",[30,0,[\"convertToUniversalTime\"]]],null]],[[\"@type\",\"@value\"],[\"datetime-local\",[28,[31,0],[[30,0,[\"localTime\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,2,[\"metadata\",\"value\"]],[[[1,\"              \"],[8,[32,3],null,[[\"@icon\",\"@action\",\"@disabled\"],[\"trash-can\",[30,0,[\"reset\"]],[30,2,[\"isDisabled\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[\"readonly\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-date-time-field.js",
      "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _dButton.default, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
    convertToUniversalTime(event1) {
      const date1 = event1.target.value;
      if (!date1) {
        return;
      }
      this.mutValue(moment(date1).utc().format());
    }
    static #_2 = (() => dt7948.n(this.prototype, "convertToUniversalTime", [_object.action]))();
    reset() {
      this.mutValue(null);
    }
    static #_3 = (() => dt7948.n(this.prototype, "reset", [_object.action]))();
    get localTime() {
      return this.args.field.metadata.value && moment(this.args.field.metadata.value).local().format(moment.HTML5_FMT.DATETIME_LOCAL);
    }
  }
  _exports.default = DateTimeField;
});